import React, { Component } from 'react';
import ReactWOW from 'react-wow'
import { withNamespaces } from 'react-i18next';
import img from '../../assets/img/gallery/01.jpg'


class About extends Component {
  render() {
      const {t} = this.props;
    return (
        <section id="about" className="text-block pt-115 pb-115">
            <div className="container">
                <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-7">
                <div className="text-img text-center text-lg-left mb-small">
                <img src={img} alt=""/>
                </div>
                </div>
                <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
                <div className="col-lg-5 col-md-8 col-sm-10">
                <div className="block-text">
                    <div className="section-title mb-20">
                    <h3>{t('aboutTitle')}</h3>
                    </div>
                    <p>
                    {t('about')}
                    </p>
             </div>
                </div>
                </ReactWOW>
            </div>
            </ReactWOW>
            </div>
        </section>
        
    );
  }
}

export default withNamespaces()(About);

