import React, { Component } from 'react';
import $ from 'jquery';
import {findDOMNode } from 'react-dom'
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-scroll";
import { withNamespaces } from 'react-i18next';
import i18n from 'i18next';
import {FaGlobe} from 'react-icons/fa';


class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.removeAll = this.removeAll.bind(this);
  }


  addClass() {
    this.setState({
      redText:true
    });
  }
  
  removeClass() {
    this.setState({
      redText:false
    });
  }
  removeAll() {
    this.setState({
      redText:false
    });
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 1020
        });
    }, false);
    window.addEventListener('load', () => {
      this.setState({
          isMobile: window.innerWidth < 1020
      });
  }, false);
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 150
      });
  }, false);
  }
  navToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).toggleClass('menu-on');
    $(nvb).toggleClass('active');
  }
  removenavToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).removeClass('menu-on');
    $(nvb).removeClass('active');
  }
  getNextSibling = function (elem, selector) {

    // Get the next sibling element
    var sibling = elem.nextElementSibling;
  
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
  
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling
    }
  
  }
  
  triggerChild = (e) => {
    let subMenu = '';
  
    subMenu = ( this.getNextSibling(e.target, '.submenu') !== undefined ) ? this.getNextSibling(e.target, '.submenu') : null;

    if(subMenu !== null && subMenu !== undefined && subMenu !== ''){
      subMenu.classList = subMenu.classList.contains('open') ? 'submenu' : 'submenu open';
    }
  }
  render() {
    const className = this.state.isMobile ? 'breakpoint-on' : '';
    const classNamess = this.state.isMobile ? 'd-none' : '';
    const classNamesss = this.state.isTop ? 'sticky-active' : '';
    
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    }
    const {t} = this.props;
    
    return (
      <div>
        {/*====== HEADER START ======*/}
        <header className={`header-absolute header-two sticky-header ${classNamesss}`}>
          <div className="container container-custom-one">
            <div className={`nav-container d-flex align-items-center justify-content-between ${className}`}>
              {/* Main Menu */}
              <div className="nav-menu d-lg-flex align-items-center" ref="navmenu">
                {/* Navbar Close Icon */}
                <div className="navbar-close" onClick={this.removenavToggle}>
                  <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                </div>
                {/* Off canvas Menu  */}
                <div className="toggle" onClick={this.addClass}>
                  <Link to="#" id="offCanvasBtn"><i className="fal fa-bars" /></Link>
                </div>
                {/* Mneu Items */}
                <div className="menu-items">
                <ul>
                  <li >
                    <Link style={{cursor: "pointer"}}  to="home" smooth={true} >{t('nav1')}</Link>
                  </li>
                  <li >
                    <Link style={{cursor: "pointer"}}  to="about" smooth={true} >{t('nav2')}</Link>
                  </li>
                  <li >
                    <Link style={{cursor: "pointer"}}  to="core-features" smooth={true} >{t('nav3')}</Link>
                  </li>
                  <li >
                    <Link  style={{cursor: "pointer"}} to="gallery" smooth={true} >{t('nav4')}</Link>
                  </li>
                  <li >
                    <Link style={{cursor: "pointer"}}  to="amenities" smooth={true} >{t('nav5')}</Link>
                  </li>
{/*                   <li >
                    <Link style={{cursor: "pointer"}}  to="testimonials" smooth={true} >{t('nav8')}</Link>
                  </li> */}
                  <li >
                    <Link style={{cursor: "pointer"}}  to="explore" smooth={true} >{t('nav6')}</Link>
                  </li>
                  <li >
                    <Link style={{cursor: "pointer"}}  to="contact" smooth={true} >{t('nav7')}</Link>
                  </li>
                  <li>
                    
                {/* <div className="header-info d-lg-flex align-items-center"> */}
                <div className="item">
                <Link>

                  <Dropdown>

                  <Dropdown.Toggle className="main-btn main-btn2  langToggle" variant="success" id="dropdown-basic"><FaGlobe/> {t('lang')}</Dropdown.Toggle>
                  
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeLanguage('en')} >English</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage('de')} >Deutch</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage('hr')} >Hrvatski</Dropdown.Item>
                   </Dropdown.Menu>
                  </Dropdown>
                  </Link>
                </div>                
                {/* </div> */}
                
                  </li>
                  </ul>
                </div>
                {/* from pushed-item */}
                <div className="nav-pushed-item" />
              </div>
              {/* Site Logo */}
{/*               <div className="site-logo">
                <Link to="/" className="main-logo"><img src={logoWhite} alt="" /></Link>
                <Link to="/" className="sticky-logo"><img src={logo} alt="" /></Link>
              </div> */}
              {/* Header Info Pussed To Menu Wrap */}
              <div className={`nav-push-item ${classNamess}`}>
                {/* Header Info */}

              </div>
              {/* Navbar Toggler */}
              <div className="navbar-toggler" onClick={this.navToggle}  ref="navbtn">
                <span /><span /><span />
              </div>
            </div>
          </div>
        </header>
        {/*====== HEADER END ======*/}
      </div>
    );
  }
}

export default withNamespaces() (Navigation);
