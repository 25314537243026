import img1 from '../../assets/img/gallery/01.jpg';
import img2 from '../../assets/img/gallery/02.jpg';
import img3 from '../../assets/img/gallery/03.jpg';
import img4 from '../../assets/img/gallery/04.jpg';
import img5 from '../../assets/img/gallery/05.jpg';
import img6 from '../../assets/img/gallery/06.jpg';
import img7 from '../../assets/img/gallery/07.jpg';
import img8 from '../../assets/img/gallery/08.jpg';
import img9 from '../../assets/img/gallery/09.jpg';
import img10 from '../../assets/img/gallery/10.jpg';
import img11 from '../../assets/img/gallery/11.jpg';
import img12 from '../../assets/img/gallery/12.jpg';
import img13 from '../../assets/img/gallery/13.jpg';
import img14 from '../../assets/img/gallery/14.jpg';
import img15 from '../../assets/img/gallery/15.jpg';
import img16 from '../../assets/img/gallery/16.jpg';
import img17 from '../../assets/img/gallery/17.jpg';
import img18 from '../../assets/img/gallery/18.jpg';
import img19 from '../../assets/img/gallery/19.jpg';
import img20 from '../../assets/img/gallery/20.jpg';
import img21 from '../../assets/img/gallery/21.png';
import img22 from '../../assets/img/gallery/22.jpg';
import img23 from '../../assets/img/gallery/23.jpg';
import img24 from '../../assets/img/gallery/24.jpg';
import img25 from '../../assets/img/gallery/25.jpg';
import img26 from '../../assets/img/gallery/26.jpg';

 const images = [
    {
      original: img1,
      thumbnail: img1,
    },
    {
      original: img2,
      thumbnail: img2,
    },
    {
      original: img3,
      thumbnail: img3,
    },
    {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
    {
      original: img7,
      thumbnail: img7,
    },
    {
      original: img8,
      thumbnail: img8,
    },
    {
      original: img9,
      thumbnail: img9,
    },
    {
      original: img10,
      thumbnail: img10,
    },
    {
      original: img11,
      thumbnail: img11,
    },
    {
      original: img12,
      thumbnail: img12,
    },
    {
      original: img13,
      thumbnail: img13,
    },
    {
      original: img14,
      thumbnail: img14,
    },
    {
      original: img15,
      thumbnail: img15,
    },
    {
      original: img16,
      thumbnail: img16,
    },
    {
      original: img17,
      thumbnail: img17,
    },
    {
      original: img18,
      thumbnail: img18,
    },
    {
      original: img19,
      thumbnail: img19,
    },
    {
      original: img20,
      thumbnail: img20,
    },
    {
      original: img21,
      thumbnail: img21,
    },
    {
      original: img22,
      thumbnail: img22,
    },
    {
      original: img23,
      thumbnail: img23,
    },
    {
      original: img24,
      thumbnail: img24,
    },
    {
      original: img25,
      thumbnail: img25,
    },
    {
      original: img26,
      thumbnail: img26,
    },
  
  ];

  export default images